<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

import type { Game } from "@/types";

const props = withDefaults(
	defineProps<{
		type?: "slider_vertical" | "slider_horizontal" | "slider_horizontal_small" | "top10-fwl";
		category?: string;
		title?: string;
		icon?: string;
		games: Game[];
		rowsPerSlide?: number;
		isShowAll?: boolean;
		isAdminIcon?: boolean;
		hideNextSlidesMobile?: boolean;
	}>(),
	{ rowsPerSlide: 1, isShowAll: true, hideNextSlidesMobile: false }
);

const router = useRouter();

const getTitle = (title?: string) => {
	if (!title) {
		return "";
	}
	return title?.toLowerCase()?.replace(/ /g, "-");
};

const getDataTid = (index?: number) => {
	if (index === 0) {
		return `slider-first-item`;
	}
	return null;
};

const COLUMNS = 12;
const sliderRef = ref();
const gamesLimited = ref(COLUMNS);
const isSliderDragging = ref(true);

const preparedGames: ComputedRef<Game[] | Array<Array<Game>>> = computed(() => {
	if (props.rowsPerSlide < 2) {
		return props.games;
	}

	return props.games.reduce<Array<Array<Game>>>((acc, item, index) => {
		const chunkIndex = Math.floor(index / props.rowsPerSlide);
		if (!acc[chunkIndex]) {
			acc[chunkIndex] = [];
		}
		acc[chunkIndex].push(item);
		return acc;
	}, []);
});

const slicedGames = computed(() => preparedGames.value?.slice(0, gamesLimited.value));

const sliderOptions: KeenSliderOptions = {
	mode: "free",
	slides: { perView: "auto", spacing: 0 },
	slideChanged(slider) {
		if (preparedGames.value?.length === slicedGames.value?.length) {
			return;
		}
		const lastColumn = slider.track.details?.slides[slicedGames.value?.length - 1]?.portion >= 0.5;

		if (lastColumn) {
			gamesLimited.value += COLUMNS;
		}
	},
	animationEnded() {
		isSliderDragging.value = false;
	}
};

const handleNavigateToGames = () => {
	if (!props.category) {
		return;
	}
	router.push(`/issues/${props.category}/`);
};

if (process.client) {
	watch(
		() => props.games?.length,
		() => {
			nextTick(() => {
				sliderRef.value?.slider?.update();
			});
		}
	);
	watch(
		() => slicedGames.value?.length && !isSliderDragging.value,
		() => {
			nextTick(() => {
				isSliderDragging.value = true;
				setTimeout(() => sliderRef.value?.slider?.update(), 500);
			});
		}
	);
}
</script>

<template>
	<section :class="[{ hideNextSlidesMobile }]">
		<MHomeSectionHeader
			:title="title"
			:icon="icon"
			:tidTitle="`slider-title-${getTitle(title)}`"
			:tidShow="`slider-${getTitle(title)}-all`"
			:is-show-all="isShowAll"
			:is-admin-icon="isAdminIcon"
			@handle-click="handleNavigateToGames"
		/>
		<ASlider ref="sliderRef" :options="sliderOptions">
			<template v-if="rowsPerSlide > 1">
				<div
					v-for="(item, index) in slicedGames as Game[][]"
					:key="`${category}-${index}`"
					class="keen-slider__slide slide-column"
				>
					<MGameType v-for="game in item" :key="game.id" :game="game" :type="type" :indexGame="index" />
				</div>
			</template>
			<template v-else>
				<MGameType
					v-for="(game, index) in slicedGames as Game[]"
					:key="game.id"
					:game="game"
					class="keen-slider__slide"
					:data-tid="getDataTid(index)"
					:type="type"
					:indexGame="index"
				/>
			</template>
		</ASlider>
	</section>
</template>
<style lang="scss" scoped>
section {
	padding: 24px 0;
	position: relative;

	@include media-breakpoint-down(lg) {
		padding: 0 16px;
	}

	&.hideNextSlidesMobile:deep(.keen-slider) {
		mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) calc(100% - 60px), rgba(0, 0, 0, 0) 100%);
	}

	&:deep(.keen-slider) {
		padding-top: 24px;
		@include media-breakpoint-up(md) {
			mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) calc(100% - 60px), rgba(0, 0, 0, 0) 100%);
		}

		@include media-breakpoint-down(md) {
			padding-top: 20px;

			&:not([data-keen-slider-disabled]) {
				overflow: visible;
			}
		}
	}

	.keen-slider__slide {
		flex-shrink: 0;
		padding-right: 8px;

		@include media-breakpoint-down(md) {
			padding-right: 12px;
		}

		&.slide-column {
			display: flex;
			flex-direction: column;
			gap: 12px;

			@include media-breakpoint-down(md) {
				gap: 12px;
			}
		}
	}
}
</style>
